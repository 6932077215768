import { doOpenSearchPage } from 'actions/pages/doOpenSearchPage'

import * as types from 'constants/action_types'

import {
  selectCurrentCommittedTicketSearchQueryString,
  selectTicketSearchQueryIdByQueryString,
} from 'selectors/search'

import {
  _splitQueryString,
  storeSubmittedQueryIdWithQueryString,
  getSubmittedQueryStringWithMailboxes,
} from 'util/search'
import {
  clearEditorAndAddBaseNode,
  recentSearchQueries,
} from 'components/ConversationList/Header/Search/util'
import {
  selectCurrentSubmittedTicketSearchFilterMatches,
  selectSearchEditor,
  selectSearchMailboxIds,
  selectTicketSearchQueryObjectByQueryString,
} from 'selectors/search/searchFilters'
import { selectIsOnSearchPage } from 'selectors/location'
import { selectKnownMailboxes } from 'ducks/mailboxes/selectors/selectKnownMailboxes'
import { runOnNextTick } from 'util/functions'
import { selectCurrentQuery } from 'ducks/searches/selectors/selectCurrentQuery'

export const CLEAR_SEARCH_TAG = 'clear-search'

// Only search for ticket number if the search query is a number and no other filters are applied
const amendSearchQueryStringIfSearchByNumber = (queryString, state) => {
  const ticketSearchQueryObject = selectTicketSearchQueryObjectByQueryString(
    state,
    queryString
  )

  const searchQueryObjectKeys = Object.keys(ticketSearchQueryObject)

  const { keywords = [] } = ticketSearchQueryObject
  // Ticket number: #123 or 123
  const possibleTicketNumber = keywords[0]?.replace(/^#/, '')
  const isTicketNumberSearch =
    searchQueryObjectKeys.length === 1 &&
    keywords.length === 1 &&
    !isNaN(possibleTicketNumber)
  return isTicketNumberSearch ? possibleTicketNumber : queryString
}

const valueMapOptions = { disableLabels: true }
export function doUpdateTicketSearchQuery(queryString, options) {
  const {
    commit = true,
    position,
    reset = false,
    currentPart,
    submit = true,
    shouldIncludeSearchMailboxes,
  } = options
  return (dispatch, getState) => {
    const state = getState()
    const trimmedQueryString = queryString && queryString.trim()
    const searchMailboxIds = selectSearchMailboxIds(state)
    const mailboxes = selectKnownMailboxes(state)
    const isSearchingAllMailboxes = searchMailboxIds.length === mailboxes.length
    const submittedQueryString = submit
      ? getSubmittedQueryStringWithMailboxes({
          searchMailboxIds,
          queryString: trimmedQueryString,
          shouldIncludeSearchMailboxes: shouldIncludeSearchMailboxes
            ? !isSearchingAllMailboxes
            : false,
        })
      : ''

    dispatch({
      type: types.UPDATE_CURRENT_TICKET_SEARCH_QUERY,
      data: {
        commit,
        position,
        queryString: submit ? submittedQueryString : queryString,
        currentPart,
        submit,
        reset,
      },
    })

    if (!submit) {
      return
    }
    const isOnSearchPage = selectIsOnSearchPage(state)
    if (submittedQueryString) {
      recentSearchQueries.value = submittedQueryString
      const term = amendSearchQueryStringIfSearchByNumber(
        submittedQueryString,
        getState()
      )
      // Replace label values with IDs for search URL
      const searchQueryId = selectTicketSearchQueryIdByQueryString(
        state,
        term,
        valueMapOptions
      )
      // we need to store the submitted search query string in session storage to persist it on refresh
      storeSubmittedQueryIdWithQueryString([
        searchQueryId,
        submittedQueryString,
      ])

      if (!isOnSearchPage) {
        // Fix search term isn't showing in the editor when search from other pages:
        // Because we are dismounting the editor when navigating away from subapp pages,
        // we need to wait for the editorState to be updated in the store before we can open the search page
        runOnNextTick(() => dispatch(doOpenSearchPage(searchQueryId)))
      } else {
        dispatch(doOpenSearchPage(searchQueryId))
      }
    }
  }
}

export function doToggleListSearchBoxFocused(isFocused) {
  return {
    type: types.TOGGLE_LIST_SEARCH_BOX_STATUS,
    data: { isFocused },
  }
}

export const doSubmitTicketsSearch = (
  query,
  { shouldIncludeSearchMailboxes } = {}
) => {
  return (dispatch, getState) => {
    const state = getState()
    const committedSearchQuery = selectCurrentCommittedTicketSearchQueryString(
      state
    )
    const searchQuery = query || committedSearchQuery
    const splitQueryString = _splitQueryString(searchQuery)
    const queryWithoutEmptyFilters = splitQueryString
      ? splitQueryString.filter(part => !part.endsWith(':')).join(' ')
      : ''

    dispatch(
      doUpdateTicketSearchQuery(queryWithoutEmptyFilters, {
        commit: true,
        reset: false,
        submit: true,
        shouldIncludeSearchMailboxes,
      })
    )
    return selectCurrentQuery(getState())
  }
}

export const doUpdateSearchMailboxIds = mailboxes => {
  return {
    type: types.SEARCH_MAILBOXES_UPDATE,
    payload: {
      mailboxes,
    },
  }
}

export const doClearSearch = (
  _e,
  { shouldBlur, shouldSubmit = true, onlyClearInput } = {}
) => (dispatch, getState) => {
  const state = getState()
  const searchEditor = selectSearchEditor(state)
  const currentSubmittedTicketSearchFilterMatches = selectCurrentSubmittedTicketSearchFilterMatches(
    state
  )
  const queryString =
    onlyClearInput && shouldSubmit
      ? currentSubmittedTicketSearchFilterMatches.join(' ')
      : ''
  if (!searchEditor) {
    return
  }

  searchEditor.update(clearEditorAndAddBaseNode, {
    tag: CLEAR_SEARCH_TAG,
    onUpdate: () => {
      if (shouldBlur) {
        searchEditor.blur()
      }
    },
  })
  dispatch(
    doUpdateTicketSearchQuery(queryString, {
      commit: true,
      submit: shouldSubmit,
      reset: true,
      shouldIncludeSearchMailboxes: false,
    })
  )
}

export const doUpdateSearchByKey = (key, value) => {
  return {
    type: types.SEARCH_UPDATE_BY_KEY,
    payload: { key, value },
  }
}
