import {
  INTEGRATIONS_INTEGRATION_SELECTED,
  INTEGRATIONS_METHOD_CHANGED,
  INTEGRATIONS_UPDATE_FORM_VALUE,
  INTEGRATION_CREATE_GITHUB_ISSUE_REQUEST,
  INTEGRATION_CREATE_GITHUB_ISSUE_SUCCESS,
  INTEGRATION_CREATE_GITHUB_ISSUE_FAILURE,
  INTEGRATION_FETCH_GITHUB_REPOS_REQUEST,
  INTEGRATION_FETCH_GITHUB_REPOS_SUCCESS,
  INTEGRATION_FETCH_GITHUB_REPOS_FAILURE,
  INTEGRATION_FETCH_GITHUB_REPO_DETAILS_REQUEST,
  INTEGRATION_FETCH_GITHUB_REPO_DETAILS_FAILURE,
  INTEGRATION_FETCH_GITHUB_REPO_DETAILS_SUCCESS,
  INTEGRATION_FETCH_GITHUB_ISSUES_REQUEST,
  INTEGRATION_FETCH_GITHUB_ISSUES_FAILURE,
  INTEGRATION_FETCH_GITHUB_ISSUES_SUCCESS,
  INTEGRATIONS_ATTACH_TO_TICKET_REQUEST,
  INTEGRATIONS_ATTACH_TO_TICKET_SUCCESS,
  INTEGRATIONS_ATTACH_TO_TICKET_FAILURE,
  INTEGRATIONS_CLEAN_FORM_VALUES,
  INTEGRATION_SET_NEXT_STEP_DEPENDENCY_FIELDS,
  INTEGRATION_FORM_SET_REQUIRED_FIELDS,
  INTEGRATION_FORM_SHOW_VALIDATION_ERRORS,
} from 'constants/action_types'
import { selectIsInChat } from 'selectors/location'
import {
  selectFormElementValue,
  selectIntegrationFormCurrentValues,
  selectSelectedGithubIssueData,
} from 'selectors/userIntegrations'
import { selectCurrentConversationUrl } from 'selectors/tickets'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { oauthTokenSelector } from 'selectors/app'
import { doShowModal, doHideModal } from 'actions/modals'
import { INTEGRATIONS as integrationModal } from 'constants/modal_types'
import * as github from 'api/github'
import { integrationChangesetMutation } from 'api/tickets'
import { doSendChangeset } from 'actions/tickets/changeset/doSendChangeset'
import integrationPayload from 'optimistic/integration'
import doLinkResourceToRoom from 'ducks/integrations/operations/doLinkResourceToRoom'
import { doFetchTicketLinkedResources } from 'actions/tickets/linkedResources'
import { selectCurrentFolderId } from 'ducks/searches/selectors/selectCurrentFolderId'

export const doSelectIntegration = item => dispatch =>
  dispatch({
    item,
    type: INTEGRATIONS_INTEGRATION_SELECTED,
  })

export const doChangeIntegrationMethod = item => dispatch =>
  dispatch({
    item,
    type: INTEGRATIONS_METHOD_CHANGED,
  })

export const doShowIntegrationModal = params => dispatch =>
  dispatch(doShowModal(integrationModal, params))

export const doHideIntegrationModal = () => dispatch =>
  dispatch(doHideModal(integrationModal))

export const doFetchGithubRepoDetails = () => (dispatch, getState) => {
  dispatch({ type: INTEGRATION_FETCH_GITHUB_REPO_DETAILS_REQUEST })
  const state = getState()
  const token = oauthTokenSelector(state)
  const repo = selectFormElementValue(state)('repo')
  return Promise.all([
    github.listLabels(token, repo),
    github.listAssignees(token, repo),
  ])
    .then(([labels, assignees]) => {
      return dispatch({
        type: INTEGRATION_FETCH_GITHUB_REPO_DETAILS_SUCCESS,
        item: { labels, assignees },
      })
    })
    .catch(err =>
      dispatch({
        type: INTEGRATION_FETCH_GITHUB_REPO_DETAILS_FAILURE,
        item: err,
      })
    )
}

export const doFetchGithubRepoIssues = () => (dispatch, getState) => {
  dispatch({ type: INTEGRATION_FETCH_GITHUB_ISSUES_REQUEST })
  const state = getState()
  const repo = selectFormElementValue(state)('repo')
  return github
    .listIssues(oauthTokenSelector(state), repo)
    .then(issues => {
      return dispatch({
        type: INTEGRATION_FETCH_GITHUB_ISSUES_SUCCESS,
        item: issues,
      })
    })
    .catch(err =>
      dispatch({
        type: INTEGRATION_FETCH_GITHUB_ISSUES_FAILURE,
        item: err,
      })
    )
}

export const doFetchGithubRepos = () => (dispatch, getState) => {
  dispatch({ type: INTEGRATION_FETCH_GITHUB_REPOS_REQUEST })
  return github
    .listRepos(oauthTokenSelector(getState()))
    .then(repos =>
      dispatch({ type: INTEGRATION_FETCH_GITHUB_REPOS_SUCCESS, item: repos })
    )
    .catch(err =>
      dispatch({ type: INTEGRATION_FETCH_GITHUB_REPOS_FAILURE, item: err })
    )
}

export const doUpdateFormValue = item => dispatch =>
  dispatch({
    item,
    type: INTEGRATIONS_UPDATE_FORM_VALUE,
  })

export const doCleanFormValue = item => dispatch =>
  dispatch({
    item,
    type: INTEGRATIONS_CLEAN_FORM_VALUES,
  })

export const doSetNextStepDependencies = deps => dispatch =>
  dispatch({
    type: INTEGRATION_SET_NEXT_STEP_DEPENDENCY_FIELDS,
    item: deps,
  })

export const doSendIntegrationChangeset = ({
  ticketId,
  id,
  provider,
  removed,
  title,
  url,
}) => (dispatch, getState) => {
  const state = getState()
  const integrationData = {
    external_id: id,
    provider,
    removed,
    title,
    url,
  }
  const optimisticData = integrationPayload(
    state,
    ticketId,
    integrationData,
    selectCurrentFolderId(state),
    null,
    removed
  )

  dispatch({
    type: INTEGRATIONS_ATTACH_TO_TICKET_REQUEST,
    item: { provider, title, url, id },
  })

  return dispatch(
    doSendChangeset(
      ticketId,
      { integration: integrationData },
      {
        optimisticData,
        mutation: integrationChangesetMutation,
      }
    )
  )
    .then(data => {
      dispatch({
        type: INTEGRATIONS_ATTACH_TO_TICKET_SUCCESS,
        item: data,
      })
      dispatch(doFetchTicketLinkedResources(ticketId))
    })
    .catch(err =>
      dispatch({
        type: INTEGRATIONS_ATTACH_TO_TICKET_FAILURE,
        item: err,
      })
    )
}

export const doLinkResource = options => (dispatch, getState) => {
  const state = getState()
  // Falling back to current ticket because doLinkResource is used all over the
  // place.
  const ticketId = options.ticketId || selectCurrentTicketId(state)
  const isChat = selectIsInChat(state)
  if (!ticketId) return null
  if (isChat) {
    return dispatch(doLinkResourceToRoom({ ...options, roomId: ticketId }))
  }
  return dispatch(
    doSendIntegrationChangeset({
      ...options,
      ticketId,
    })
  )
}

export const doAttachGithubIssueToTicket = () => (dispatch, getState) => {
  const state = getState()
  const token = oauthTokenSelector(state)
  const ticketUrl = selectCurrentConversationUrl(state)
  const {
    title,
    html_url: url,
    id,
    number,
    repository_url: repoUrl,
  } = selectSelectedGithubIssueData(state)
  return Promise.all([
    dispatch(doLinkResource({ title, url, id, provider: 'github' })),
    github.attachTicketToIssue(
      token,
      repoUrl,
      number,
      ticketUrl,
      'This is referenced in the following Groove conversation:'
    ),
  ])
}

export const doCreateGithubIssue = () => (dispatch, getState) => {
  dispatch({ type: INTEGRATION_CREATE_GITHUB_ISSUE_REQUEST })
  const state = getState()
  const token = oauthTokenSelector(state)
  const data = selectIntegrationFormCurrentValues(state)
  const ticketUrl = selectCurrentConversationUrl(state)
  return github
    .createIssue(oauthTokenSelector(state), data)
    .then(payload => {
      dispatch({
        type: INTEGRATION_CREATE_GITHUB_ISSUE_SUCCESS,
        item: payload,
      })
      return payload
    })
    .then(payload => {
      const {
        title,
        html_url: url,
        id,
        number,
        repository_url: repoUrl,
      } = payload
      return Promise.all([
        dispatch(doLinkResource({ title, url, id, provider: 'github' })),
        github.attachTicketToIssue(
          token,
          repoUrl,
          number,
          ticketUrl,
          'Created from the following Groove conversation:'
        ),
      ])
    })
    .catch(err =>
      dispatch({ type: INTEGRATION_CREATE_GITHUB_ISSUE_FAILURE, item: err })
    )
}

export const doSetRequiredFields = fields => dispatch => {
  return dispatch({ type: INTEGRATION_FORM_SET_REQUIRED_FIELDS, item: fields })
}

export const doShowValidationErrors = () => dispatch => {
  return dispatch({ type: INTEGRATION_FORM_SHOW_VALIDATION_ERRORS })
}
