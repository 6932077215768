import { CONVERSATION_SAVE_UNLOAD_MESSAGE } from 'ducks/tickets/constants'
import { getLength, isEmpty } from 'util/arrays'
import { batchActions } from 'util/redux'
import windowUnload from 'util/windowUnload'

const changesetRequestQueue = {}
const changesetRequestTracker = {}
const changesetActionQueue = {}

let disableRefreshNextPage = false

const anyRequestsLeft = () => {
  return [].concat(...Object.values(changesetRequestQueue)).length > 0
}

const shouldDisableRefreshNextPage = () => {
  return anyRequestsLeft() || disableRefreshNextPage
}

const anyRequestsLeftForId = id => {
  if (!changesetRequestQueue[id]) return false
  if (changesetRequestQueue[id].length <= 0) return false
  return true
}

const runNextRequest = id => {
  const nextRequest = changesetRequestQueue[id].shift()
  return nextRequest()
}

const cleanup = id => {
  delete changesetActionQueue[id]
  delete changesetRequestTracker[id]
  delete changesetRequestQueue[id]

  windowUnload.uninstall() // eslint-disable-line import/no-named-as-default-member
  disableRefreshNextPage = false
}

const init = id => {
  if (!changesetActionQueue[id]) changesetActionQueue[id] = []
  if (!changesetRequestQueue[id]) changesetRequestQueue[id] = []
}

const doAllQueuedActions = id => dispatch => {
  const actions = changesetActionQueue[id] || []
  cleanup(id)
  if (getLength(actions) <= 0) return false
  if (getLength(actions) === 1) return dispatch(actions[0])
  return dispatch(batchActions(actions))
}

// This function will queue the given `action` to a queue, and run the next
// request.
//
// If there are no more requests to run, it will return all the queued actions
//
const handleAction = (dispatch, id, action, { after = [] } = {}) => {
  init(id)

  changesetActionQueue[id].push(action)

  if (!isEmpty(after)) {
    changesetActionQueue[id] = changesetActionQueue[id].concat(after)
  }

  if (anyRequestsLeftForId(id)) {
    runNextRequest(id)
    return false
  }

  return dispatch(doAllQueuedActions(id))
}

const handleComplete = handleAction

const handleFail = handleAction

const scheduleRequest = (id, request) => {
  init(id)

  return new Promise((resolve, reject) => {
    changesetRequestQueue[id].push(() => {
      request(id, resolve, reject)
    })
  })
}

// Expects a function with this signature
//
//   runRequest(id, resolve, reject)
//
const track = (id, request, disableRefreshPage) => {
  // eslint-disable-next-line import/no-named-as-default-member
  windowUnload.install(CONVERSATION_SAVE_UNLOAD_MESSAGE)
  disableRefreshNextPage = disableRefreshPage
  if (changesetRequestTracker[id] === true) return scheduleRequest(id, request)

  changesetRequestTracker[id] = true
  return request(id)
}

export default {
  track,
  handleComplete,
  handleFail,
  anyRequestsLeft,
  shouldDisableRefreshNextPage,
}
