import { v4 as uuidV4 } from 'uuid'
import { BEGIN, REVERT } from 'redux-optimist'
import graphql from 'api/graphql'
import { singleChangesetDefinition } from 'api/tickets'
import * as types from 'constants/action_types'
import { oauthTokenSelector } from 'selectors/app'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { selectPrefersOpenFolderOnTicketSave } from 'ducks/currentUser/selectors/preferences/selectPrefersOpenFolderOnTicketSave'
import { selectCurrentUserPrefersAutoadvanceToNextTicket } from 'ducks/currentUser/selectors/preferences/selectCurrentUserPrefersAutoadvanceToNextTicket'
import { selectNextTicketIdInList } from 'selectors/ticket_list'
import { doOpenNewTicketPage } from 'actions/pages'
import debug from 'util/debug'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import { runOnNextTick } from 'util/functions'
import ChangesetQueue from '../changesetQueue'
import { applyAutomaticActionsToChangeset } from './applyAutomaticActionsToChangeset'
import { buildSingleChangesetInput } from './buildSingleChangesetInput'

export function doSendChangeset(ticketId, data, options = {}) {
  const {
    userInitiated = true,
    optimisticData = null,
    disableNavigateToFolder = false,
    changesetId: overrideChangesetId,
    preserveDraft = false,
    mutation = singleChangesetDefinition,
    // Don't refresh the next page if true (see refreshMiddleware)
    disableRefreshPage,
  } = options

  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const changesetId = overrideChangesetId || uuidV4()
    let variables = buildSingleChangesetInput(
      ticketId,
      data,
      state,
      userInitiated,
      changesetId
    )
    const isForNewTicket = data.ticketId === 'new'

    variables.skipTicketTouch = options.skipTicketTouch

    if (data.automaticActions) {
      variables = applyAutomaticActionsToChangeset(
        variables,
        data.automaticActions
      )
    }

    const isCustomerChangeHappened = !!variables.customer
    const isIntegrationChange = !!variables.integration
    const isStarring = !!variables.priority

    // NOTE: We need to get this before the optimistic update applies any diffs
    const nextTicketId = selectNextTicketIdInList(state) || null

    // (Optimistically) navigate based on the ticket save user preference. We
    // only do this if there is no message (ie. a note or a reply) (It's
    // expected that the doSendReply/Note functions handle the on_ticket_reply
    // and on_note_add preferences).
    //
    // NOTE: The order is important! It needs to come after the (optimistic)
    // changeset request so folder/searches already have diffs applied
    //
    // (pnagy) we never want to navigate after user change action
    // (pnagy) we never want to navigate after integreation change action
    // (pnagy) we never want to navigate after starring or unstarring
    if (
      userInitiated &&
      !disableNavigateToFolder &&
      !isCustomerChangeHappened &&
      !isIntegrationChange &&
      !isStarring
    ) {
      runOnNextTick(() => {
        const classicView = selectPrefersClassicView(state)
        if (classicView) {
          const shouldOpenFolder = selectPrefersOpenFolderOnTicketSave(state)
          // const routeAction = selectCurrentTicketListRouteAction(state)
          const autoAdvance = selectCurrentUserPrefersAutoadvanceToNextTicket(
            state
          )
          // NOTE: I don't know if this shouldOpenFolder thing is even a thing
          // anymore. I don't see it in the new settings pages
          if (autoAdvance && shouldOpenFolder /* && routeAction */) {
            // Removed temporarily. This whole function falls away once we move to
            // gql v2
            // dispatch(routeAction)
          }
        } else if (nextTicketId) {
          // Removed temporarily. This whole function falls away once we move to
          // gql v2
          // dispatch(doAutoRedirect(ticketId, nextTicketId))
        }
      })
    }

    // While the actual API request may be delayed, scheduled, we want this to
    // execute immediately all the time, since it applies our optimistic updates
    dispatch({
      type: types.CREATE_CHANGESET_REQUEST,
      data: {
        ...optimisticData,
        preserveDraft,
        draft: data, // in case we need to undo a send
      },
      optimist: optimisticData ? { type: BEGIN, id: changesetId } : null,
      meta: {
        requestId: changesetId,
        isForNewTicket,
      },
    })

    const failAction = {
      type: types.CREATE_CHANGESET_FAIL,
      optimist: optimisticData ? { type: REVERT, id: changesetId } : null,
    }

    const runRequest = (id, resolve, reject) => {
      const getData = graphql(token, mutation, variables, changesetId).then(
        res => {
          const response = res.json.data
          const responseData = response.createChangeset
          responseData.diff = JSON.parse(responseData.diff) || {}
          return responseData
        }
      )
      const currentQueryId = selectCurrentQueryId(state)

      const successAction = getData.then(responseData => {
        return {
          type: types.CREATE_CHANGESET_SUCCESS,
          data: {
            ...responseData,
            ticketId: responseData.id,
            ticketData: responseData.ticket,
            actions: responseData.actions.records,
            diff: {
              ...responseData.diff,
              searches: {
                ...(optimisticData && optimisticData.diff
                  ? optimisticData.diff.searches
                  : null),
                ...responseData.diff.searches,
              },
            },
            options: { currentQueryId },
            preserveDraft,
            draft: data, // in case we need to undo a send
          },
          meta: {
            requestId: changesetId,
            isForNewTicket,
          },
          // Kevin Rademan: Right, I changed this to commit rather than revert.
          // Glenn originally said that this can/will cause in incorrect counts
          // but we'll have to deal with that some other way as reverting the change
          // causes other issues with sorting.
          optimist: optimisticData ? { type: REVERT, id: changesetId } : null,
        }
      })

      return (
        successAction
          .then(action => {
            const result = ChangesetQueue.handleComplete(dispatch, id, action)
            return result
          })
          .then(() => {
            if (resolve) resolve(getData)
            return getData
          })
          .catch(err => {
            debug(err)
            ChangesetQueue.handleFail(dispatch, id, failAction)
            if (isForNewTicket) {
              dispatch(doOpenNewTicketPage())
            }
            throw err
          })
          // intentionally chain the rejections so that any exceptions in the
          // code above are properly rejected, and the queues dont end up in a
          // bad state.
          .catch(err => {
            if (reject) {
              reject(err)
            } else {
              throw err
            }
          })
      )
    }

    return ChangesetQueue.track(ticketId, runRequest, disableRefreshPage)
  }
}
