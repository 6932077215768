/* eslint-disable import/first */
/* eslint-disable global-require */
import config from 'config'
import '@neocoast/semantic-ui-less/semantic.less'
import '@groovehq/internal-design-system/lib/styles/global/preflight/index.css'
import '@groovehq/internal-design-system/lib/styles/global/utility/index.css'
import 'whatwg-fetch'
// Polyfill
require('intersection-observer') // Polyfill - load before other modules
require('css.escape')

import store from 'store'
import startIntersectionObserver from 'util/intersectionObserver'
import sharedStorage from 'util/sharedStorage'
import storage from 'util/storage'
import { captureVersion } from 'util/version'
import { refreshOnNextPage } from 'util/refreshMiddleware'
import React from 'react'
import ReactDOM from 'react-dom'
import metrics from 'util/metrics'
import memory from 'util/memoryUsageMiddleware'
import * as salesforce from 'ducks/integrations/salesforce/api'

import { initializeTracking, trackPage } from 'util/tracking'
import { cacheRegistry } from 'util/memoization'
import { doShowSnackbar } from 'actions/snackbar'
import fpsExtension from 'util/fpsAppExtensions'
import { registerListeners as registerActionsLogListener } from 'util/performance_sim/listeners'
import * as ticketInfo from 'util/ticketInfo'
import * as agentInfo from 'util/agentInfo'
import * as mailboxInfo from 'util/mailboxInfo'
import { doRefreshSearches } from 'ducks/searches/operations/doRefreshSearches'

function hydrateApp(preApp) {
  const logs = []

  window.trackPage = trackPage

  initializeTracking()

  if (process.env.NODE_ENV !== 'test') {
    // The current userID is cached in LocalStorage, but we need it in IndexedDB so
    // that the ServiceWorker can access it too.
    sharedStorage.setCurrentUserId((storage.get('currentUser') || {}).id)
    const cachedAuth = storage.get('auth')
    if (cachedAuth) sharedStorage.setAPIAuthToken(cachedAuth.token)
    sharedStorage.setGraphQLUrl(config.graphql_url)
    cacheRegistry.startAutomaticInvalidation()
  }

  const version = captureVersion()
  const path = `/${!config.isDevelopment ? 'app-release' : ''}`

  const app = {
    version,
    path,
    salesforce,
    pathPrefix: path === '/' ? '' : path,
    env: config.env,
    config,
    actions: require('./util/builder').actions,
    store,
    storage,
    intersectionObserver: startIntersectionObserver(),
    logs,
    metrics,
    cache: cacheRegistry,
    memory: memory.installProfiling({ store }),
    enableHiddenFeatures: () => {
      storage.set('showSettings', true)
      storage.set('showOnboarding', true)
      storage.set('showAIMessage', true)
      window.location.reload()
    },
    enableShopifyV2LegacyInstallMode: () => {
      // don't delete this even after shopify v2 launch (and don't tell Shopify we have this feature lol)
      // it basically enables the old way of integrating with shopify (where we initiate the oauth dance and not through shopify app store install)
      storage.set('showShopifyV2LegacyInstallMode', true)
      window.location.reload()
    },
    log: message => {
      console.log(message) //  eslint-disable-line no-console
      logs.push(message)
    },
    doShowSnackbar: message => {
      store.dispatch(doShowSnackbar(message))
    },
    doRefreshCounts: () => {
      store.dispatch(doRefreshSearches())
    },
    overlayRoot: document.getElementById('overlays'),
    refreshOnNextPage,
    ticketInfo,
    agentInfo,
    mailboxInfo,
  }

  // use this for features exposed to customers to execute in dev console
  const features = {
    showHiddenSnoozeOptions: () => {
      storage.set('showHiddenSnoozeOptions', true)
    },
  }

  if (config.isAlpha || config.isDevelopment || config.isDocker) {
    fpsExtension(app)
  }

  // register listeners for performance simulator
  registerActionsLogListener()

  global.app = { ...preApp, ...app }
  global.features = features
  global.React = React
  global.ReactDOM = ReactDOM
}

module.exports = {
  hydrateApp,
}
